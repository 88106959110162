import { FC, Suspense, lazy, useCallback, useMemo } from 'react'
import { Outlet } from 'react-router'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { Col, Divider, Flex, Row, Tabs, Typography } from 'antd'

import { Button } from '@peoplevine/sdk/components/button/Button'
import IvyIcon from '@peoplevine/sdk/components/icon'
import { Avatar } from '@peoplevine/sdk/components/image/Avatar'
import { SectionLoader } from '@peoplevine/sdk/components/loader'
import Page from '@peoplevine/sdk/components/page/Page'
import { useResponsive } from '@peoplevine/sdk/components/screen/Breakpoint'
import Text from '@peoplevine/sdk/components/text/Text'
import { Title } from '@peoplevine/sdk/components/text/Title'
import { useAuth } from '@peoplevine/sdk/providers/Auth'
import { useConfig } from '@peoplevine/sdk/providers/ConfigProvider'
import { useFeatureToggles } from '@peoplevine/sdk/providers/Feature'
import { routes } from '@peoplevine/sdk/routes'

import { useAccount } from './Provider'
import './index.scss'

const Contact = lazy(() => import('./tabs/Contact'))
const Billing = lazy(() => import('./tabs/billing/Billing'))
const Memberships = lazy(() => import('./tabs/membership/Memberships'))
const Profile = lazy(() => import('./tabs/profile'))
const Wallet = lazy(() => import('./tabs/wallet/Wallet'))
const MySchedule = lazy(() => import('../my-schedule/MySchedule'))

const AccountInfo: FC = () => {
  const { customer } = useAccount()
  const { logout } = useAuth()
  const { image, initials, fullName, email } = customer
  return (
    <Row
      style={{ width: '100%', minWidth: 200 }}
      wrap={false}
      gutter={[16, 16]}
      align={'middle'}
      justify={'space-between'}
    >
      <Col flex={'none'}>
        <Avatar src={image} text={initials} size={72} />
      </Col>
      <Col flex={'auto'}>
        <Title translate={'no'} level={5} style={{ marginBottom: 4 }}>
          {fullName}
        </Title>
        <Typography.Text ellipsis type={'secondary'}>
          {email}
        </Typography.Text>
      </Col>
      <Col flex={'none'}>
        <Button
          type={'link'}
          icon={<IvyIcon color={'secondary'} type={'custom/sign-out'} size={24} />}
          onClick={logout}
        />
      </Col>
    </Row>
  )
}

const tabs = [
  {
    label: 'Profile',
    key: 'profile',
    Component: Profile,
  },
  {
    label: 'Memberships',
    key: 'memberships',
    Component: Memberships,
  },
  {
    label: 'My Schedule',
    key: 'schedule',
    Component: MySchedule,
  },
  {
    label: 'Wallet',
    key: 'wallet',
    Component: Wallet,
  },
  {
    label: 'Billing',
    key: 'billing',
    Component: Billing,
  },
  {
    label: 'Contact',
    key: 'contact',
    Component: Contact,
  },
]

const Account: FC = () => {
  const { '*': section } = useParams()
  const { hasContact } = useFeatureToggles()
  const {
    account: { components },
  } = useConfig().props.sections

  const navigate = useNavigate()
  const { isDesktop } = useResponsive()

  const changeTab = useCallback(
    (key: string) => navigate(generatePath(routes.accountSection, { section: key })),
    [navigate],
  )

  const activeKey = section?.split('/').at(0)

  const updatedComponents = useMemo(() => {
    return {
      ...components,
      schedule: {
        title: 'My Schedule',
        enabled: true,
      },
    }
  }, [components])

  const accountTabs = useMemo(() => {
    return tabs
      .filter((it) => updatedComponents[it.key]?.enabled)
      .filter((it) => (it.key === 'contact' && hasContact) || it.key !== 'contact')
      .map(({ label, key }) => ({
        key: key,
        label: (
          <Text type={activeKey === key ? 'primary' : 'default'} translate={'yes'}>
            {updatedComponents?.[key]?.title || label}
          </Text>
        ),
      }))
  }, [activeKey, updatedComponents, hasContact])

  return (
    <Page title={'Account'}>
      <Row gutter={[24, 16]}>
        <Col span={24} lg={7}>
          <Tabs
            activeKey={activeKey}
            onChange={changeTab}
            items={accountTabs}
            style={{ height: '100%' }}
            tabPosition={isDesktop ? 'left' : 'top'}
            renderTabBar={(props, DefaultTabBar) => (
              <Flex
                vertical
                style={{ flexDirection: isDesktop ? 'column-reverse' : 'column', width: '100%' }}
                justify={'left'}
              >
                <DefaultTabBar
                  {...props}
                  indicator={{ size: isDesktop ? 0 : undefined }}
                  style={{ marginLeft: isDesktop ? -24 : undefined }}
                />
                {isDesktop && <Divider />}
                <AccountInfo />
              </Flex>
            )}
          />
        </Col>
        <Col span={24} lg={17}>
          <Suspense fallback={<SectionLoader />}>
            <Outlet />
          </Suspense>
        </Col>
      </Row>
    </Page>
  )
}
export default Account

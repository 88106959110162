import { FC, MouseEvent, useCallback } from 'react'

import { Skeleton } from 'antd'
import { createStyles } from 'antd-style'

import parse, { HTMLReactParserOptions } from 'html-react-parser'
import sanitizeHtml from 'sanitize-html'

import { useColor } from '../../app'
import './Markup.scss'
import { applyMarkupRule } from './MarkupRules'

type MarkupProps = SDK.Components.TextProps & {
  sanitized: boolean
  children: string
  terms?: boolean
  blog?: boolean
}

const useStyles = createStyles(({ token, css }: { token: any; css: any }) => ({
  markup: css`
    color: ${token.colorText} !important;

    h2,
    h3,
    h4,
    h5 {
      color: ${token.headingColor};
      font-family: ${token.headingFontFamilySecondary};
    }
    
    h1 {
      font-family: ${token.headingFontFamily};
      font-size: ${token.fontSizeHeading1}px;
      line-height: ${token.lineHeightHeading1};
    }
    h2 {
      font-size: ${token.fontSizeHeading2}px;
      line-height: ${token.lineHeightHeading2};
    } 
    h3 {
      font-size: ${token.fontSizeHeading3}px;
      line-height: ${token.lineHeightHeading3};
    }   
    h4 {
      font-size: ${token.fontSizeHeading4}px;
      line-height: ${token.lineHeightHeading4};
    }
    h5 {
      font-size: ${token.fontSizeHeading5}px;
      line-height: ${token.lineHeightHeading5};
    }
    p {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    img {
      display: block;
      margin: 24px auto;
      max-width: 100%;
    }
    
    a.ant-btn-primary {
      font-size: 14px;
      height: 48px;
      padding: 12px 24px;
      border-radius: 4px;
      background: ${token.btnPrimaryBg}!important;
      font-family: ${token.btnFontFamily}!important;
      font-weight: bold;
      border-color: ${token.btnPrimaryBorderColor}!important;
      color: ${token.btnPrimaryColor} !important;
      text-transform: ${token.btnTextTransform} !important;
      &:hover {
        background: ${token.btnPrimaryHoverBg} !important;
        border-color: ${token.btnPrimaryHoverBorderColor} !important;
        color: ${token.btnPrimaryHoverColor} !important;
      }
    }
  }
  `,
}))

const Markup: FC<Partial<MarkupProps>> = ({
  sanitized = true,
  children,
  loading = false,
  terms = false,
  blog = false,
  type = 'secondary',
}) => {
  const { textByVariant } = useColor()
  const { styles } = useStyles()
  const parsedHTML = useCallback((html: string) => {
    const unescapeHTML = (html: string) => html.replace(/\\"/g, '"').replace(/\\'/g, "'")
    const options: HTMLReactParserOptions = {
      replace: applyMarkupRule,
    }
    return parse(unescapeHTML(html), options)
  }, [])

  const scrollToElement = (scrollId: string) => {
    const targetElement =
      (document.querySelector(`[name="${scrollId}"]`) as HTMLElement) ||
      (document.getElementById(scrollId) as HTMLElement)
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 20,
        behavior: 'smooth',
      })
    }
  }

  const handleScrollTo = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as HTMLAnchorElement
    const href = target.getAttribute('href')

    if (href?.startsWith('#')) {
      const scrollId = href.substring(1)
      event.preventDefault()
      scrollToElement(scrollId)
    }
  }

  return loading ? (
    <Skeleton />
  ) : children ? (
    !sanitized ? (
      <div
        className={[terms ? 'terms' : blog ? 'blog' : 'markup', styles.markup].join(' ')}
        style={{ lineHeight: 1.5, color: textByVariant(type) }}
        data-cy='Markup'
        onClick={handleScrollTo}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(children.replaceAll('&nbsp;', ' ').trim(), {
            allowedAttributes: {
              'div': ['style'],
              'a': ['href', 'name', 'data-scroll-target', 'target', 'class', 'style'],
              'img': ['src', 'style'],
              '*': ['style'],
            },
            allowedSchemes: ['http', 'https', 'mailto'],
            allowedTags: [
              'a',
              'b',
              'br',
              'em',
              'div',
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'i',
              'img',
              'li',
              'ol',
              'p',
              'span',
              'strong',
              'ul',
            ],
          }),
        }}
      />
    ) : (
      <div className={[terms ? 'terms' : blog ? 'blog' : 'markup', styles.markup].join(' ')}>
        {parsedHTML(children)}
      </div>
    )
  ) : null
}

export { Markup }
export default Markup

import { type CSSProperties, Dispatch, SetStateAction, createContext, useContext } from 'react'

import { App } from 'antd'

import Color from 'color'
import kebabcase from 'lodash.kebabcase'
import validator from 'validator'

import defaults from '../../configs/portal/theme.json'
import { ColorKeys, DefaultTheme as defaultTheme } from '../../themes/default'

const light: SDK.Components.ThemeToken = {
  mode: 'light',
  boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
  boxShadowSecondary:
    '0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
  boxShadowTertiary: '0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
  fontFamily: 'Roboto',
  fontFamilySecondary: 'Jost',
  fontFamilyCode: 'Fira Code',
  colorPrimary: '#57806e',
  colorBgBase: '#ffffff',
  colorBgLayout: '#ffffff',
  colorTextBase: '#000000',
  colorBgContainer: '#ffffff',
  colorBgComponent: '#e8e7e9',
  colorBgImage: '#f8f8f8',
  colorBorder: '#e8e7e9',
  colorBorderSecondary: '#e7e9e8',
  colorBgElevated: '#ffffff',
  colorBgSpotlight: '#212224',
  colorError: '#e98365',
  colorErrorActive: '#e06c51',
  // colorErrorBg: '#fff2f0',
  colorErrorBgHover: '#fff1f0',
  colorErrorBorderHover: '#e06c51',
  colorTextDisabled: '#868690',
  colorErrorText: '#ff4444',
  colorErrorTextActive: '#ff4444',
  colorInfo: '#33b5e5',
  colorInfoActive: '#FFF6E8',
  // colorInfoBg: '#FFF6E8',
  colorInfoBgHover: '#f8e4b4',
  // colorInfoBorder: '#FFF6E8',
  colorInfoBorderHover: '#f8e4b4',
  colorInfoHover: '#ffffff',
  colorSuccess: '#ceeee3',
  colorSuccessActive: '#b1d8c9',
  colorSuccessBgHover: '#b1d8c9',
  colorBgTagDefault: '#e7e9e8',
  colorTagDefault: '#6e6d79',
  colorSuccessBgNotify: '#CEEEE3FF',
  colorSuccessBorderHover: '#b1d8c9',
  colorSuccessHover: '#b1d8c9',
  colorText: '#212224',
  colorTextQuaternary: '#f8f8f8',
  colorTextSecondary: '#6e6d79',
  colorTextTertiary: '#868690',
  colorWarning: '#f7b193',
  colorWarningActive: '#f7b193',
  colorWarningBg: '#fdccae',
  colorWarningBgHover: '#f7b193',
  colorWarningBorder: '#fdccae',
  colorWarningBorderHover: '#f7b193',
  colorWarningHover: '#f7b193',
  carouselDotOffset: 32,
  colorWhite: '#ffffff',
  colorBlack: '#000000',

  borderRadius: 4,
  controlHeight: 40,
  linkFocusDecoration: 'none',
  fontSize: 14,
  lineHeight: 1.3,
  size: 16,

  motionBase: 0.1,
  lineWidth: 1,
  lineWidthBold: 2,
  motion: true,
  motionUnit: 0.1,
  opacityImage: 1,
  sizePopupArrow: 16,
  sizeStep: 4,
  sizeUnit: 4,

  wireframe: false,
  zIndexBase: 0,
  zIndexPopupBase: 1000,
} as const

function isColor(value: string | undefined) {
  return value ? validator.isHexColor(value) || validator.isRgbColor(value) || validator.isHSL(value) : false
}

function resolveToken(token: SDK.Components.ThemeToken): SDK.Components.ThemeToken {
  return {
    ...token,
    colorText: token.colorTextBase,
    borderRadius: +token.borderRadius,
    colorBorder: token.colorBorder,
    colorBorderSecondary: isColor(token.colorBorder) ? Color(token.colorBorder).darken(0.1).hex() : undefined,
    fontSize: +token.fontSize,
    colorLink: token?.colorPrimary,
    colorPrimaryBg: token?.colorPrimary,
    colorPrimaryBorder: token?.colorPrimary,
    colorPrimaryText: token?.colorPrimary,
    colorSuccessText: token?.colorPrimary,
    colorTextReverse: token.colorWhite,
    colorInfoText: token?.colorInfoText,
    colorInfoTextActive: token?.colorInfoActive,
    colorInfoTextHover: token?.colorInfoActive,
    colorBgLayout: token?.colorBgLayout,
    colorTextDisabled: token?.colorTextDisabled,
    colorTextSecondary: token?.colorTextSecondary,
    colorTextPlaceholder: token?.colorTextBase,
    colorBgContainerDisabled: token?.colorBgContainer,
    colorBgTextHover: 'transparent',
    paddingContentHorizontal: 24,
    paddingContentVertical: 16,

    paddingContentHorizontalSM: 16,
    paddingContentHorizontalMD: 24,
    paddingContentHorizontalLG: 32,

    paddingContentVerticalSM: Math.floor(+token.size * 0.5),
    paddingContentVerticalMD: Math.floor(+token.size * 0.75),
    paddingContentVerticalLG: Math.floor(+token.size * 1.25),

    lineHeightLG: Math.ceil(+token.lineHeight + 0.1),
    lineHeightSM: Math.ceil(+token.lineHeight + 0.2),
    lineHeightHeading1: +token?.lineHeight,
    lineHeightHeading2: +token?.lineHeight,
    lineHeightHeading3: +token?.lineHeight,
    lineHeightHeading4: +token?.lineHeight,
    lineHeightHeading5: +token?.lineHeight,

    borderRadiusLG: +token?.borderRadius * 2,
    borderRadiusSM: +token?.borderRadius / 2,
    borderRadiusXS: +token?.borderRadius / 2,
    borderRadiusOuter: +token.borderRadius * 2,

    fontSizeSM: +token?.fontSize - 2,
    fontSizeLG: +token?.fontSize + 2,
    fontSizeXL: +token?.fontSize + 4,

    sizeXXS: Math.floor(+token?.size * 0.25),
    sizeXS: Math.floor(+token?.size * 0.5),
    sizeSM: Math.floor(+token?.size * 0.75),
    sizeMD: Math.floor(+token?.size * 1.25),
    sizeLG: Math.floor(+token?.size * 1.5),
    sizeXL: Math.floor(+token?.size * 2),
    sizeXXL: Math.floor(+token?.size * 3),

    controlHeightLG: Math.floor(+token?.controlHeight * 1.25),
    controlHeightSM: Math.floor(+token?.controlHeight * 0.75),
    controlHeightXS: Math.floor(+token?.controlHeight * 0.5),
  }
}

function resolveComponents(token: SDK.Components.ThemeToken): SDK.Components.ComponentsConfig {
  return {
    'App': {
      fontFamily: token?.fontFamily,
      colorBorder: token?.colorBorder,
      borderRadius: token.borderRadius,
    },
    'Alert': {
      defaultPadding: 12,
      withDescriptionPadding: '16px 24px',
      withDescriptionIconSize: 12,
    },
    'Badge': {
      indicatorHeight: 20,
    },
    'Breadcrumb': {
      fontSize: Math.floor(+token.fontSize * 0.75),
    },
    'Drawer': { colorBgContainer: token.colorBgLayout },
    'Layout': {
      headerHeight: 96,
      footerHeight: 96,
      headerPadding: '0 40px',
      lightSiderBg: token?.colorBgSider,
      bodyPaddingVerticalSM: 24,
      bodyPaddingHorizontalSM: 24,
      bodyPaddingVerticalLG: 16,
      bodyPaddingHorizontalLG: 40,
      bodyBg: token?.colorBgLayout,
      siderBg: token?.colorBgSider,
      headerBg: token?.colorBgLayout,
      footerBg: token?.colorBgLayout,
    },
    'Layout.Sider': {
      width: token?.layoutSiderWidth ?? 280,
      collapsedWidth: token?.layoutSiderCollapsedWidth ?? 112,
    },
    'ColorPicker': {
      borderRadius: +token.borderRadius,
    },
    'Input': {
      activeBorderColor: Color(token?.colorPrimary).fade(0.6).hexa(),
      activeShadow: 'none',
      paddingInline: 11,
      paddingBlock: 13.9,
      inputFontSize: token.fontSize,
      lineWidth: 1,
    },
    'Checkbox': { checkboxSize: 16 },
    'Radio': {
      buttonBg: token?.colorBgContainerDisabled,
      buttonCheckedBg: token.colorPrimary ? Color(token.colorPrimary).fade(0.85).hexa() : undefined,
      buttonSolidCheckedColor: token.colorPrimary,
      buttonSolidCheckedHoverBg: token.colorPrimary ? Color(token.colorPrimary).fade(0.85).hexa() : undefined,
      buttonSolidCheckedBg: token.colorPrimary ? Color(token.colorPrimary).fade(0.85).hexa() : undefined,
    },
    'Spin': { dotSizeSM: 16 },
    'Menu': {
      activeBarBorderWidth: 0,
      darkItemBg: token.colorBgSider,
      darkItemColor: token.colorWhite,
      darkItemDisabledColor: token.colorTextDisabled,
      darkItemHoverBg: token.colorBgSider,
      darkItemHoverColor: token.colorPrimary,
      fontSize: +token.fontSize + 1,
      iconMarginInlineEnd: 20,
      iconSize: Math.floor(+token.fontSize * 1.5),
      itemActiveBg: 'transparent',
      itemBg: token.colorBgSider,
      itemBorderRadius: 0,
      itemColor: token.colorText,
      itemHeight: token.controlHeight,
      itemHoverColor: token.colorPrimary,
      itemMarginInline: 0,
      itemSelectedBg: token.colorPrimary, // Keep transparent to avoid unexpected bg behavior on active/selected conflicts
      subMenuItemBg: token.colorBgSider,
      subMenuItemBorderRadius: 0,
    },

    'Modal': {
      bodyPadding: 0,
      titleFontSize: 20,
      contentPadding: 24,
    },
    'QRCode': {
      borderRadius: 0,
      colorBg: 'transparent',
      mask: isColor(token.colorBgLayout) ? Color(token.colorBgLayout).fade(0.1).hexa() : undefined,
    },
    'Button': {
      borderRadius: +token?.borderRadius,
      primaryShadow: 'none',
      primaryBg: token.btnPrimaryBg,
      dangerShadow: 'none',
      defaultShadow: 'none',
      defaultHoverBg: token.colorBgHover,
      defaultBg: token?.colorBgContainerDisabled,
      ghostBg: 'transparent',
      defaultGhostBorderColor: token?.colorBorder,
      fontFamily: token?.fontFamilySecondary,
      fontWeight: 700,
      dangerColor: token.colorWarning,
    },
    'Descriptions': {
      fontFamily: token?.fontFamilySecondary,
      titleMarginBottom: 8,
    },
    'Typography': {
      titleMarginBottom: 0,
      fontWeightStrong: token?.fontWeightStrong,
      titleMarginTop: 0,
    },
    [`Typography.Title`]: {
      fontFamily: token?.fontFamilySecondary,
    },
    [`Login`]: {
      bgBlur: token.cardBlurBgTransparency,
    },
    ['Table']: {
      colorBgContainer: 'transparent',
      cellPaddingInlineMD: 8,
      // expandIconSize: 17,
      expandIconBg: 'transparent',
      borderColor: 'transparent',
      footerBg: token.colorBgLayout,
    },
    [`Timeline`]: {
      tailWidth: 1,
    },
    ['Tag']: {
      defaultBg: token.colorBgTagDefault,
      defaultColor: token.colorTagDefault,
    },
  }
}

function useColor() {
  const { token, components } = useContext(Context)
  const bgByVariant = (type: SDK.Components.ColorVariant | undefined, fade: boolean = false) => {
    const fadeValue = fade ? 0.7 : 0
    switch (type) {
      case 'primary':
        return color(token.colorPrimary).lighten(0.25).fade(fadeValue).hexa()
      case 'primary-light':
        return color(token.colorPrimary).fade(fadeValue).hexa()
      case 'secondary':
        return token?.colorBgContainer
      case 'success':
        return token?.colorSuccess
      case 'warning':
        return color(token?.colorWarning).fade(fadeValue).hexa()
      case 'error':
      case 'danger':
        return color(token?.colorError).fade(fadeValue).hexa()
      case 'processing':
        return color(token?.colorInfo).fade(fadeValue).hexa()
      case 'info':
        return color(token?.colorInfo).fade(fadeValue).hexa()
      case 'light':
        return token?.colorWhite
      case 'dark':
        return token?.colorBgSpotlight
      case 'accent':
        return token?.colorPrimary
      case 'default':
        return token?.colorBgTagDefault
      case undefined:
      case 'ghost':
        return token?.colorBgDisable
      case 'none':
        return 'unset'
    }
  }

  const textByBgVariant = (type: SDK.Components.ColorVariant | undefined) => {
    switch (type) {
      case 'danger':
      case 'dark':
        return textByVariant('light')
      case 'error':
      case 'warning':
        return token?.colorError
      case 'primary':
        return token?.colorPrimary
      case 'success':
        return token?.colorSuccess
      case 'info':
        return token?.colorInfo
      case 'light':
      case 'secondary':
        return token?.colorTextSecondary
      case 'default':
        return token?.colorTagDefault
      case undefined:
      case 'ghost':
      default:
        return token?.colorTextBase
    }
  }

  const textByVariant = (type: SDK.Components.ColorVariant | undefined) => {
    switch (type) {
      case 'primary':
        return token?.colorPrimaryText
      case 'secondary':
        return token?.colorTextSecondary
      case 'success':
        return token?.colorSuccessText
      case 'error':
        return token?.colorErrorText
      case 'warning':
        return token?.colorWarning
      case 'danger':
        return token?.colorError
      case 'info':
        return token?.colorInfoText
      case 'light':
        return token?.colorWhite
      case 'none':
        return undefined
      case undefined:
      case 'inherit':
      case 'default':
      default:
        return token.colorTextBase
    }
  }

  const resolveUnit = (
    value: string | number,
    { unit = 'px', add = 0, subtract = 0 }: { unit?: string; add?: number; subtract?: number } = {},
  ): string => {
    if (typeof value === 'string') {
      return value.endsWith(unit) ? `${value}` : `${value}${unit}`
    } else {
      value = subtract ? value - subtract : add ? value + add : value
      return `${value}${unit}`
    }
  }

  const color = (value: Parameters<typeof isColor>[0]) => {
    const defaultColor = '#ffffffff' as const
    return isColor(value) ? Color(value) : Color(defaultColor)
  }

  return {
    token,
    components,
    color,
    resolveUnit,
    textByVariant,
    textByBgVariant,
    bgByVariant,
  }
}

function resolveStyles(token: SDK.Components.ThemeToken): { [key: string]: { style: CSSProperties } } {
  return {
    button: { style: { textTransform: 'uppercase', boxShadow: 'none' } },
    menu: { style: { textTransform: 'uppercase', fontWeight: 700 } },
    breadcrumb: { style: { fontSize: +token?.fontSize * 0.8 } },
    card: { style: { transition: 'none' } },
  }
}

function resolveCssVarReferences<T extends Partial<CamelCasedProps<{ [key in ColorKeys]: string }>>>(
  lessVars: T,
): Partial<{ [key in ColorKeys]: string }> {
  var pattern = /var\(--(.*?)\)/g
  var varRegex = new RegExp(pattern)

  // Recursive function to resolve CSS variables from other CSS variables
  const processVariable = (value: string) => {
    let cssVarName: string | undefined
    /// Finding and replacing var(--) variables with correct value
    if (varRegex.test(value)) {
      cssVarName = value.replace(pattern, '@$1')
    } else if (value.includes('@')) {
      /// Finding and replacing @ variables with correct value
      cssVarName = value.replace(/.*@([^,()]*-[^,()]*).*/g, '@$1')
    }
    if (cssVarName) {
      let valueMatch = lessVars[cssVarName]
      if (valueMatch)
        return varRegex.test(valueMatch) || valueMatch.includes('@') ? processVariable(valueMatch) : valueMatch
    }

    return value
  }
  return Object.entries(lessVars)
    .map((item) => {
      const key = item[0]
      const value = processVariable(item[1])
      return { [kebabcase(key)]: value }
    })
    .reduce((acc, it) => ({ ...acc, ...it }), {})
}

type AppHook = () => SDK.Components.DrawerHook & ReturnType<typeof App.useApp> & ContextProps

type ContextProps = {
  token: SDK.Components.ThemeToken
  setTokenValue: Dispatch<SetStateAction<SDK.Components.ThemeToken>>
  components: SDK.Components.ComponentsConfig
}
const token = resolveToken(light)
const components = resolveComponents(token)
const defaultValue: ContextProps = {
  token,
  setTokenValue: (prevState) => prevState,
  components,
}

const withConfigColors = (colors: Partial<{ [key in ColorKeys]: string }>) => {
  // console.log(colors)
  const vars = resolveCssVarReferences(colors)
  const resolveDefault = (value: string): string => {
    const resolvedValue = vars[value]
    if (resolvedValue && resolvedValue in defaults) {
      return resolveDefault(resolvedValue)
    } else {
      return resolvedValue
    }
  }
  const resolve = (value: string): string | number | undefined => {
    const resolvedValue = vars[value]

    if (resolvedValue) {
      if (resolvedValue === 'unset' || resolvedValue === 'inherit' || resolvedValue === 'transparent')
        return defaultTheme[`@${value}`]
      if (resolvedValue.length <= 6 && resolvedValue.match(/[0-9.][px]/))
        return Number.parseInt(resolvedValue.replace('px', ''))
      return resolvedValue
    } else {
      const defaultKey = `@${value}`
      if (defaultKey in defaultTheme) return resolveDefault(defaultKey)
      return undefined
    }
  }

  type ComponentColorMap<
    T extends keyof NonNullable<SDK.Components.ComponentsConfig>,
    V = NonNullable<SDK.Components.ComponentsConfig[T]>,
  > = { [key in keyof V]: ColorKeys }
  const convertedToken: {
    token: { [key in keyof SDK.Components.ThemeToken]: ColorKeys }
    components: {
      [K in keyof SDK.Components.ComponentsConfig]: { [key in keyof SDK.Components.ComponentsConfig[K]]: ColorKeys }
    }
  } = {
    components: {
      ['Button']: {
        borderColorDisabled: '@btn-disable-border',
        defaultBg: '@btn-default-bg',
        primaryColor: '@btn-primary-color',
        defaultBorderColor: '@btn-default-border',
        defaultColor: '@btn-default-color',
        defaultGhostBorderColor: '@btn-ghost-border',
        defaultGhostColor: '@btn-ghost-color',
        defaultHoverColor: '@btn-default-hover-color',
        defaultHoverBorderColor: '@btn-default-hover-border',
        defaultHoverBg: '@btn-default-hover-bg',
        ghostBg: '@btn-ghost-bg',
        textHoverBg: '@btn-default-hover-bg',
        linkHoverBg: '@btn-primary-hover-bg',
      } as ComponentColorMap<'Button'>,
      ['Breadcrumb']: {
        itemColor: '@breadcrumb-base-color',
        linkColor: '@breadcrumb-link-color',
        linkHoverColor: '@breadcrumb-link-color-hover',
        lastItemColor: '@breadcrumb-last-item-color',
        separatorColor: '@breadcrumb-separator-color',
      } as ComponentColorMap<'Breadcrumb'>,
      ['Form']: {
        labelColor: '@label-color',
        labelFontSize: '@form-item-label-font-size',
      } as ComponentColorMap<'Form'>,
      ['Input']: {
        hoverBorderColor: '@input-hover-border-color',
        activeBorderColor: '@input-hover-border-color',
      } as ComponentColorMap<'Input'>,
      ['Select']: {
        hoverBorderColor: '@input-hover-border-color',
        activeBorderColor: '@input-hover-border-color',
      } as ComponentColorMap<'Select'>,
      ['InputNumber']: {
        hoverBorderColor: '@input-hover-border-color',
        activeBorderColor: '@input-hover-border-color',
      } as ComponentColorMap<'InputNumber'>,
      ['Layout']: {
        bodyBg: '@layout-body-background',
        siderBg: '@menu-bg',
        footerHeight: '@layout-footer-height',
        headerHeight: '@layout-header-height',
        // headerBg: '@layout-header-background',
      } as ComponentColorMap<'Layout'>,
      ['Layout.Header']: {} as ComponentColorMap<'Layout.Header'>,
      ['Menu']: {
        collapsedIconSize: '@menu-collapsed-width',
        darkItemBg: '@menu-dark-bg',
        darkItemColor: '@white',
        darkItemSelectedBg: '@primary-color',
        iconMarginInlineEnd: '@menu-icon-margin-right',
        iconSize: '@menu-icon-size',
        itemActiveBg: '@primary-color',
        itemBg: '@menu-bg',
        itemColor: '@menu-item-color',
        itemHeight: '@menu-item-height',
        itemHoverBg:"@menu-item-hover-bg",
        itemHoverColor: '@menu-item-hover-color',
        itemSelectedBg: '@primary-color',
        itemSelectedColor: '@menu-item-active-color', // popupBg: '@menu-dark-bg',
        colorPrimary: '@btn-primary-bg',
        textTransform: '@menu-item-text-transform',
        fontWeight: '@menu-item-font-weight',
        // subMenuItemBg: '@menu-bg',
      } as ComponentColorMap<'Menu'>,
      ['Card']: {
        headerBg: '@card-bg',
        actionsBg: '@card-bg',
        colorBorderSecondary: '@border-color-base',
      } as ComponentColorMap<'Card'>,
      ['Table']: {
        borderColor: '@table-border-color',
        // expandIconBg: '@table-expand-icon-bg',
        expandIconColor: '@table-expand-icon-color',
        rowHoverBg: '@table-row-hover-bg',
      } as ComponentColorMap<'Table'>,
    },
    token: {
      avatarBg: '@avatar-bg',
      borderRadius: '@border-radius-base',
      btnDefaultBg: '@btn-default-bg',
      btnDefaultBorderColor: '@btn-default-border',
      btnDefaultColor: '@btn-default-color',
      btnDefaultHoverBg: '@btn-default-hover-bg',
      btnDefaultHoverBorderColor: '@btn-default-hover-border',
      btnDefaultHoverColor: '@btn-default-hover-color',
      btnFontFamily: '@btn-font-family',
      btnGhostBg: '@btn-ghost-bg',
      btnGhostBorderColor: '@btn-ghost-border',
      btnGhostColor: '@btn-ghost-color',
      btnGhostHoverBg: '@btn-ghost-hover-bg',
      btnGhostHoverBorderColor: '@btn-ghost-hover-border',
      btnGhostHoverColor: '@btn-ghost-hover-color',
      btnPrimaryBg: '@btn-primary-bg',
      btnPrimaryBorderColor: '@btn-primary-border-color',
      btnPrimaryColor: '@btn-primary-color',
      btnPrimaryHoverBg: '@btn-primary-hover-bg',
      btnPrimaryHoverBorderColor: '@btn-primary-hover-border-color',
      btnPrimaryHoverColor: '@btn-primary-hover-color',
      btnTextTransform: '@btn-text-transform',
      cardBadgeBg: '@card-badge-bg',
      cardBadgeColor: '@card-badge-color',
      cardBg: '@card-bg',
      cardHoverBorder: '@card-hoverable-hover-border',
      cardShadow: '@card-shadow',
      cardHoverShadow: '@card-hoverable-hover-shadow',
      colorAccent: '@accent-color',
      colorBgContainerDisabled: '@disabled-color',
      colorBgDisable: '@btn-disable-bg',
      colorBgElevated: '@modal-bg',
      colorBgImage: '@avatar-bg',
      colorBgLayout: '@body-background',
      colorBgSider: '@layout-sider-background',
      colorBgTagDefault: '@tag-default-bg',
      colorBorder: '@border-color-base',
      colorBorderHover: '@border-color-focused',
      colorBorderSecondary: '@border-color-focused',
      colorError: '@error-color',
      colorErrorBorder: '@alert-error-border-color',
      colorErrorText: '@alert-error-message-color',
      colorIcon: '@icon-color',
      colorInfo: '@info-color',
      colorInfoText: '@info-color',
      colorInfoTextActive: '@toast-info-title-color',
      colorInfoTextHover: '@text-color-secondary',
      colorLink: '@link-color',
      colorLinkActive: '@link-active-color',
      colorLinkHover: '@link-hover-color',
      colorPrimary: '@primary-color',
      colorPrimaryBg: '@primary-color',
      colorSuccess: '@success-color',
      colorSuccessBg: '@alert-success-bg-color',
      colorSuccessBorder: '@alert-success-border-color',
      colorSuccessText: '@success-color',
      colorTagDefault: '@tag-default-color',
      colorText: '@text-color',
      colorTextBase: '@text-color',
      colorTextLabel: '@label-color',
      colorTextPlaceholder: '@text-color-secondary',
      colorTextQuaternary: '@text-color-secondary',
      colorTextSecondary: '@text-color-secondary',
      colorTextTertiary: '@text-color-secondary',
      colorWarning: '@warning-color',
      colorWarningBg: '@alert-warning-bg-color',
      colorWarningBorder: '@alert-warning-border-color',
      controlHeight: '@input-height-base',
      controlItemBgActive: '@list-item-active-bg',
      fontFamily: '@font-family-primary',
      fontFamilySecondary: '@font-family-secondary',
      fontSize: '@font-size-base',
      fontSizeHeading1: '@heading-1-size',
      fontSizeHeading2: '@heading-2-size',
      fontSizeHeading3: '@heading-3-size',
      fontSizeHeading4: '@heading-4-size',
      fontSizeHeading5: '@heading-5-size',
      inputHoverBorder: '@input-hover-border-color',
      headingColor: '@heading-color',
      headingColorSecondary: '@heading-secondary-color',
      headingFontFamily: '@heading-font-family',
      headingFontFamilySecondary: '@heading-secondary-font-family',
      headingFontWeight: '@heading-font-weight',
      headingSecondaryFontWeight: '@heading-secondary-font-weight',
      headingSecondaryTransform: '@heading-secondary-transform',
      headingTransform: '@heading-transform',
      menuItemFontWeight: '@menu-item-font-weight',
      menuItemFontSize: '@menu-item-font-size',
      menuItemFontFamily: '@menu-item-font-family',
      menuItemTextTransform: '@menu-item-text-transform',
      listItemHoverBg: '@list-item-hover-bg',
      tagDefaultBg: '@tag-default-bg',
      tagDefaultColor: '@tag-default-color',
      tagFontFamily: '@tag-font-family',
    },
  }

  function convertToken(value: { [key: string]: ColorKeys }) {
    // console.log(Object.entries(value).map(([key,value])=>({[value]:key})).reduce((acc, it) => ({ ...acc, ...it }), {}))

    return Object.entries(value)
      .map(([key, colorKey]) => ({ [key]: resolve(colorKey.replace('@', '')) }))
      .reduce((acc, it) => ({ ...acc, ...it }), {})
  }

  function convertComponents(tree: { [key: string]: { [key: string]: ColorKeys } }) {
    // console.log(
    //   Object.entries(tree)
    //     .map(([key, value]) => ({
    //       [key]: Object.entries(value)
    //         .map(([key, colorKey]) => ({ [colorKey]: key }))
    //         .reduce((acc, it) => ({ ...acc, ...it }), {}),
    //     }))
    //     .flatMap(Object.values).reduce((acc, it) => ({ ...acc, ...it }), {}),
    // )
    return Object.entries(tree)
      .map(([key, value]) => ({
        [key]: Object.entries(value)
          .map(([key, colorKey]) => ({ [key]: resolve(colorKey.replace('@', '')) }))
          .reduce((acc, it) => ({ ...acc, ...it }), {}),
      }))
      .reduce((acc, it) => ({ ...acc, ...it }), {})
  }

  return { token: convertToken(convertedToken.token), components: convertComponents(convertedToken.components) }
}

const Context = createContext<ContextProps>(defaultValue)
const Drawer = createContext<SDK.Components.DrawerHook>({
  hide: () => {},
  show: () => {},
  render: () => {},
})

const useApp: AppHook = () => {
  const drawerContext = useContext(Drawer)
  const appContext = App.useApp()
  const context = useContext(Context)
  return { ...context, ...drawerContext, ...appContext }
}

export {
  Context,
  defaultValue,
  light,
  resolveComponents,
  resolveCssVarReferences,
  resolveStyles,
  resolveToken,
  useApp,
  useColor,
  withConfigColors,
  type ContextProps,
}

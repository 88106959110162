import { FC, memo, useMemo } from 'react'

import { Avatar as BaseAvatar, Flex, Spin } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'

import { useConfig } from 'src/providers'

import { useColor } from '../app'
import IvyIcon from '../icon'
import { Image } from './Image'

const assets: Record<`./assets/placeholders/${Design.PlaceholderIcon}.svg`, string> = import.meta.glob(
  './assets/placeholders/*.svg',
  {
    import: 'default',
    eager: true,
  },
)

const AvatarSpinner = ({ shape, size }: { shape: 'square' | 'circle'; size: number }) => (
  <Flex
    justify={'center'}
    align={'center'}
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: size,
      height: size,
      transform: 'translate(-50%, -50%)',
      borderRadius: shape === 'circle' ? '50%' : 0,
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 1,
      opacity: 0.8,
    }}
  >
    <Spin indicator={<LoadingOutlined spin />} size='small' />
  </Flex>
)

// Replace CDN domain with portal domain - Ensures Token is passed with request via proxy middleware
const ResolveCdnSource = (src: string) => {
  const { cdn } = useConfig()
  if (!src.startsWith('http') || src.startsWith(cdn)) {
    src = `${src.replace(cdn, `${location.protocol}//${location.host}/cdn`)}`
  }
  return src
}

const Avatar: FC<SDK.Components.AvatarProps> = ({
  size = 160,
  loading = false,
  text: children,
  background,
  icon,
  shape: defaultShape = 'circle',
  src,
  placeholder,
  color = 'default',
  style,
  ...props
}) => {
  const { token, bgByVariant, textByVariant, color: Color } = useColor()
  const backgroundColor = background ? bgByVariant(background) : token.avatarBg
  const iconColor =  Color(backgroundColor).isDark() ? token.colorWhite : token.colorText
  const shape = !!defaultShape ? defaultShape : icon ? 'square' : 'circle'
  const loader = useMemo(() => loading && <AvatarSpinner shape={shape} size={size} />, [loading, shape, size])
  return (
    <>
      {loader}
      <BaseAvatar
        shape={shape}
        size={size}
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          margin: 0,
          backgroundColor,
          backgroundSize: size / 2,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          border: shape === 'circle' ? `1px solid ${token.colorBorder}` : undefined,
          color: iconColor,
          ...style,
        }}
        src={
          typeof src === 'string' && src ? (
            (src = ResolveCdnSource(src))
          ) : typeof icon === 'string' ? (
            <IvyIcon color={'primary'} type={icon as Design.IvyIcon} height={size / 2} />
          ) : (
            icon
          )
        }
        {...props}
      >
        {typeof src === 'string' && !children ? <Image placeholder={placeholder} src={src} /> : children}
      </BaseAvatar>
    </>
  )
}
const AvatarMemo = memo(Avatar)
export { AvatarMemo as Avatar }
